/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";

//Redux
import {useSelector} from "react-redux"

//Components
import InputArea from "../InputArea/InputArea";

//Localized
import buttonsLocalized from "../../../localized/buttonsLocalized.json";
import contactData from "../../../localized/contactLocalized.json";

const Form = ({
  //Common variables
  firstName,
  lastName,
  email,
  setFirstName,
  setLastName,
  setEmail,

  //Waiting List variables
  companySizeArrayList,
  companyName,
  selectedCompanySize,
  setCompanyName,
  setSelectedCompanySize,
  sendWaitingListRequest,

  //Contact us variables
  subject,
  messageContent,
  setSubject,
  setMessageContent,
  sendContactUsRequest,

  // Get demo Variables
  sendGetDemoRequest,
  phoneNumber,
  setPhoneNumber,
  companyRole,
  setCompanyRole,
  country,
  setCountry,
  allCountries,

  //Others
  pageContentType,
}) => {
  // Current Language
  const currentLanguage = useSelector(state => state.changeLanguageReducer)

  // Submit Button Functions
  const [waitListSubmitDisabled, setWaitlistSubmitDisabled] = useState(true);
  const [contactUsSubmitDisabled, setContactUsSubmitDisabled] = useState(true);
  const [getDemoSubmitDisabled, setGetDemoSubmitDisabled] = useState(true);

  /* */

  /* USE EFFECTS */
  
  // Toggling submit status for waitlist
  useEffect(() => {
    if (pageContentType === "waitingList") {
      if (
        companyName.length > 0 &&
        selectedCompanySize &&
        firstName.length > 0 &&
        lastName.length > 0 &&
        email.length > 0
      ) {
        setWaitlistSubmitDisabled(false);
      } else {
        setWaitlistSubmitDisabled(true);
      }
    }

  }, [firstName, lastName, email, companyName, selectedCompanySize])

  // Toggling submit status for contact-us
  useEffect(() => {
    if (pageContentType === "contactUs") {
      if (
        firstName.length > 0 &&
        lastName.length > 0 &&
        email.length > 0 &&
        subject.length >= 5 &&
        messageContent.length >= 30
      ) {
        setContactUsSubmitDisabled(false);
      } else {
        setContactUsSubmitDisabled(true);
      }
    }

  }, [firstName, lastName, email, subject, messageContent])

  // Toggling submit status for get-demo
  useEffect(() => {
    if (pageContentType === "getDemo") {
      if (
        firstName.length > 0 &&
        lastName.length > 0 &&
        email.length > 0 &&
        phoneNumber.length > 0 &&
        companyRole.length > 0 &&
        companyName.length > 0 &&
        selectedCompanySize
      ) {
        setGetDemoSubmitDisabled(false);
      } else {
        setGetDemoSubmitDisabled(true);
      }
    }

  }, [firstName, lastName, email, phoneNumber, companyRole, companyName, selectedCompanySize])

  // useEffect(() => {
  //   setCurrentLanguage(localStorage.getItem('language'))
  // },[currentLanguage])

  /* */

  return (
    <div className="contact_form">
      <div>
        <div id="form-title">
          <span id="form-title-text">
            {pageContentType === "waitingList"
              ? contactData.joinOurWaitingList?.[currentLanguage]
              : pageContentType === "contactUs"
                ? contactData.contactUs?.[currentLanguage]
                : contactData.getDemo?.[currentLanguage]
            }
          </span>
          {pageContentType === "waitingList"
              ? <p>&#127919;</p>
              : pageContentType === "contactUs" 
                ? <p>&#128075;</p>
                : <p>&#128640;</p>
          }
        </div>
        {pageContentType === "waitingList" ? (
          <form onSubmit={sendWaitingListRequest}>
            <div className="w-100 contact_form_content">
              <div className="row w-100">
                <div className="col-12 col-md-6">
                  <label className="muni_label">
                    {contactData.name?.[currentLanguage]}*
                  </label>
                  <InputArea
                    changeText={setFirstName}
                    currentValue={firstName}
                    placeholder="Avatar"
                    className="w-100"
                    required={true}
                  />
                </div>
                <div className="col-12 col-md-6">
                  <label className="muni_label">
                    {contactData.lastname?.[currentLanguage]}*
                  </label>
                  <InputArea
                    changeText={setLastName}
                    currentValue={lastName}
                    placeholder="Aang"
                    className="w-100"
                    required={true}
                  />
                </div>
              </div>
              <div className="row w-100">
                <div className="col-12">
                  <label className="muni_label">
                    {contactData.email?.[currentLanguage]}*
                  </label>
                  <InputArea
                    changeText={setEmail}
                    currentValue={email}
                    placeholder="avatar.aang@sky-bison.com"
                    className="w-100"
                    emailClass="email"
                    id="waitlist-email-input"
                    required={true}
                  />
                </div>
              </div>
              <div className="row w-100">
                <div className="col-12 col-md-6">
                  <label className="muni_label">
                    {contactData.companyName?.[currentLanguage]}*
                  </label>
                  <InputArea
                    changeText={setCompanyName}
                    currentValue={companyName}
                    placeholder="Sky Bison Airlines"
                    className="w-100"
                    required={true}
                  />
                </div>
                <div className="col-12 col-md-6 muni_select_box_container">
                  <label className="muni_label">
                    {contactData.companySize?.[currentLanguage]}*
                  </label>
                  <select
                    required={true}
                    className="muni_select_box w-100"
                    id="waitlist-company-size-select-box"
                    value={JSON.stringify(selectedCompanySize)}
                    onChange={(e) =>
                      setSelectedCompanySize(JSON.parse(e.target.value))
                    }
                    onFocus={(e) => {
                      e.target.classList.add("active_input_parent");
                    }}
                    onBlur={(e) => {
                      e.target.classList.remove("active_input_parent");
                    }}
                  >
                    {companySizeArrayList.map((size, index) => (
                      <option value={JSON.stringify(size)}>{size.size}</option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <button 
              className="website_btn discover_btn contact_form_submit_button"
              disabled={waitListSubmitDisabled}
            >
              {buttonsLocalized.send?.[currentLanguage]}
            </button>
          </form>
        ) : pageContentType === "contactUs"
          ?  (
              //Contact us form
              <form onSubmit={sendContactUsRequest}>
                <div className="w-100 contact_form_content">
                  <div className="row w-100">
                    <div className="col-12 col-md-6">
                      <label className="muni_label">
                        {contactData.name?.[currentLanguage]}*
                      </label>
                      <InputArea
                        changeText={setFirstName}
                        currentValue={firstName}
                        placeholder="Avatar"
                        className="w-100"
                        required={true}
                      />
                    </div>
                    <div className="col-12 col-md-6">
                      <label className="muni_label">
                        {contactData.lastname?.[currentLanguage]}*
                      </label>
                      <InputArea
                        changeText={setLastName}
                        currentValue={lastName}
                        placeholder="Aang"
                        className="w-100"
                        required={true}
                      />
                    </div>
                  </div>
                  <div className="row w-100">
                    <div className="col-12">
                      <label className="muni_label">
                        {contactData.email?.[currentLanguage]}*
                      </label>
                      <InputArea
                        changeText={setEmail}
                        currentValue={email}
                        placeholder="avatar.aang@sky-bison.com"
                        className="w-100"
                        emailClass="email"
                        id="contact-us-email-input"
                        required={true}
                      />
                      {/* <span>Please enter a proper email</span> */}
                    </div>
                  </div>
                  <div className="row w-100">
                    <div className="col-12">
                      <label className="muni_label">
                        {contactData.subject?.[currentLanguage]}*
                      </label>
                      <InputArea
                        changeText={setSubject}
                        currentValue={subject}
                        placeholder={contactData.subject?.[currentLanguage]}
                        className="w-100"
                        required={true}
                      />
                    </div>
                    <div className="col-12">
                      <label className="muni_label">
                        {contactData.content?.[currentLanguage]}*
                      </label>
                      <textarea
                        required={true}
                        className="w-100 contact_form_content_message"
                        placeholder={
                          contactData.typeYourRequest?.[currentLanguage]
                        }
                        value={messageContent}
                        onChange={(e) => setMessageContent(e.target.value)}
                        onFocus={(e) => {
                          e.target.classList.add("active_input_parent");
                        }}
                        onBlur={(e) => {
                          e.target.classList.remove("active_input_parent");
                        }}
                      />
                    </div>
                  </div>
                </div>
                <button 
                  className="website_btn discover_btn contact_form_submit_button"
                  disabled={contactUsSubmitDisabled}
                >
                  {buttonsLocalized.send?.[currentLanguage]}
                </button>
              </form>
            )
          : (
            <form onSubmit={sendGetDemoRequest}>
              <div className="w-100 contact_form_content">
                <div className="row w-100">
                  <div className="col-12 col-md-6">
                    <label className="muni_label">
                      {contactData.name?.[currentLanguage]}*
                    </label>
                    <InputArea
                      changeText={setFirstName}
                      currentValue={firstName}
                      placeholder="Avatar"
                      className="w-100"
                      required={true}
                    />
                  </div>
                  <div className="col-12 col-md-6">
                    <label className="muni_label">
                      {contactData.lastname?.[currentLanguage]}*
                    </label>
                    <InputArea
                      changeText={setLastName}
                      currentValue={lastName}
                      placeholder="Aang"
                      className="w-100"
                      required={true}
                    />
                  </div>
                </div>
                <div className="row w-100">
                  <div className="col-12">
                    <label className="muni_label">
                      {contactData.email?.[currentLanguage]}*
                    </label>
                    <InputArea
                      changeText={setEmail}
                      currentValue={email}
                      placeholder="avatar.aang@sky-bison.com"
                      className="w-100"
                      emailClass="email"
                      id="getDemo-email-input"
                      required={true}
                    />
                  </div>
                </div>
                <div className="row w-100">
                  <div className="col-12 col-md-6">
                    <label className="muni_label">
                      {contactData.phone?.[currentLanguage]}*
                    </label>
                    <InputArea
                      changeText={setPhoneNumber}
                      currentValue={phoneNumber}
                      className="w-100"
                      required={true}
                    />
                  </div>
                  <div className="col-12 col-md-6">
                    <label className="muni_label">
                      {contactData.companyRole?.[currentLanguage]}*
                    </label>
                    <InputArea
                      changeText={setCompanyRole}
                      currentValue={companyRole}
                      placeholder="CEO"
                      className="w-100"
                      required={true}
                    />
                  </div>
                </div>
                <div className="row w-100">
                  <div className="col-12 col-md-6">
                      <label className="muni_label">
                        {contactData.companyName?.[currentLanguage]}*
                      </label>
                      <InputArea
                        changeText={setCompanyName}
                        currentValue={companyName}
                        placeholder="Sky Bison Airlines"
                        className="w-100"
                        required={true}
                      />
                    </div>
                  <div className="col-12 col-md-6 muni_select_box_container">
                    <label className="muni_label">
                      {contactData.companySize?.[currentLanguage]}*
                    </label>
                    <select
                      required={true}
                      className="muni_select_box"
                      id="waitlist-company-size-select-box"
                      value={JSON.stringify(selectedCompanySize)}
                      onChange={(e) =>
                        setSelectedCompanySize(JSON.parse(e.target.value))
                      }
                      onFocus={(e) => {
                        e.target.classList.add("active_input_parent");
                      }}
                      onBlur={(e) => {
                        e.target.classList.remove("active_input_parent");
                      }}
                    >
                      {companySizeArrayList.map((size, index) => (
                        <option key={index} value={JSON.stringify(size)}>{size.size}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="row w-100">
                  <div className="col-12 muni_select_box_container">
                    <label className="muni_label">
                      {contactData.country?.[currentLanguage]}*
                    </label>
                    <select
                      required={true}
                      className="muni_select_box"
                      id="waitlist-company-size-select-box"
                      value={JSON.stringify(country)}
                      onChange={(e) =>
                        setCountry(JSON.parse(e.target.value))
                      }
                      onFocus={(e) => {
                        e.target.classList.add("active_input_parent");
                      }}
                      onBlur={(e) => {
                        e.target.classList.remove("active_input_parent");
                      }}
                    >
                      <option value='None' key='12345'>-</option>
                      {allCountries.map((country, index) => (
                        <option key={index} value={JSON.stringify(country)}>{country.name}</option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <button 
                className="website_btn discover_btn contact_form_submit_button"
                disabled={getDemoSubmitDisabled}
              >
                {buttonsLocalized.send?.[currentLanguage]}
              </button>
            </form>
            )
        }
      </div>
    </div>
  );
};

export default Form;
