/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react"

//Components
import Form from "../components/Tools/Form/Form"

// React Redux
import { useSelector } from "react-redux"

//React router
import { useLocation } from "@reach/router"

//Axios instance
import axios from "../axios"

// Localized Data
import contactData from "../localized/contactLocalized.json"

//Illustrations
import waitingListIllustration from "../assets/company/waitlist-illustration.svg"
import contactUsIllustration from "../assets/company/contactUs-illustration.svg"
import welcomeIllustration from "../assets/company/welcome-illustration.svg"

// Icons
import circleCheckIcon from "../assets/company/circle-check-light-black.svg"
import Layout from "../components/Layout"

const Contact = ({ id, path }) => {
  const currentLanguage = useSelector(state => state.changeLanguageReducer)
  const location = useLocation()

  const [pageContentType, setPageContentType] = useState("")

  // Regices
  const emailRegex = /^\w+([.-]\w+)*@\w+([.-]\w+)*(\.\w{2,})/

  const companySizeArrayList = [
    { key: "MI", size: "1-9" },
    { key: "SM", size: "10-49" },
    { key: "MD", size: "50-249" },
    { key: "LG", size: "250+" },
  ]

  // Common variables
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")

  // Waiting List Variables
  const [companyName, setCompanyName] = useState("")
  const [selectedCompanySize, setSelectedCompanySize] = useState({
    key: "MI",
    size: "1-9",
  })

  // Contact us variables
  const [subject, setSubject] = useState("")
  const [messageContent, setMessageContent] = useState("")

  // Get Demo Variables
  const [phoneNumber, setPhoneNumber] = useState("")
  const [companyRole, setCompanyRole] = useState("")
  const [country, setCountry] = useState(null)

  //All countries
  const [allCountries, setAllCountries] = useState([])

  // Contact Success States
  const [contactedSuccesfully, setContactedSuccesfully] = useState(false)

  // Functions
  const clearFormData = () => {
    // Falsifying the success state if true
    setContactedSuccesfully(false)

    //Common variables
    setFirstName("")
    setLastName("")
    setEmail("")

    //Waiting List Variables
    setCompanyName("")

    //Contact us variables
    setSubject("")
    setMessageContent("")

    // Get Demo variables
    setPhoneNumber("")
    setCompanyRole("")
    setCountry("")
  }

  // Creating a separate function for success
  // since it needs a scroll to top as well
  const bringContactSuccess = () => {
    setContactedSuccesfully(true)
    window.scrollTo(0, 0)
  }

  /* Checking if input is valid 
    - API takes a grandparent argument for determining which parent
    - should have the warning appended
    - If nothing or 0 is passed, the element itself is assumed
    - for appending the warning.
  */
  const invalidElementWarning = (elementId, desiredWarning, parentCount) => {
    // Getting the element
    let element = document.getElementById(elementId)

    // Making sure that element exists
    if (element) {
      // Adding warning color to the input
      element.classList.add("invalid_element")

      // Getting the parent element to add the desired
      // warning below. This depends on how nested
      // the element is
      var parentOfElement = element

      if (typeof parentCount === "number") {
        for (let i = 0; i < parentCount; i++) {
          // Making sure that no parent found situation is checked
          // In those cases, we simply revert to what we know exists - the element itself
          try {
            parentOfElement = parentOfElement.parentNode
          } catch (e) {
            parentOfElement = element
          }
        }
      }

      // Creating the warning if it doesn't already exist
      if (!parentOfElement.querySelector(".invalid_warning")) {
        var invalidWarning = document.createElement("span")
        invalidWarning.className = "invalid_warning"

        // Adding the required warning
        invalidWarning.innerHTML = desiredWarning

        parentOfElement.appendChild(invalidWarning)

        // Focusing the invalid element
        element.focus()
      }
    }
  }

  const sendWaitingListRequest = e => {
    e.preventDefault()

    // Testing the user's email
    let validWaitlistEmail = emailRegex.test(email.toLowerCase())

    if (!validWaitlistEmail) {
      invalidElementWarning(
        "waitlist-email-input",
        contactData.enterValidEmail?.[currentLanguage],
        2
      )
      return false
    }

    let newObject = {
      first_name: firstName,
      last_name: lastName,
      email: email.toLowerCase(),
      company_name: companyName,
      company_size: selectedCompanySize.key,
      language: currentLanguage,
    }
    axios.post("/muni-management/waiting-list/", newObject).then(result => {
      bringContactSuccess()
    })
  }

  const sendContactUsRequest = e => {
    e.preventDefault()
    // Testing the user's email
    let validContactUsEmail = emailRegex.test(email.toLowerCase())

    if (!validContactUsEmail) {
      invalidElementWarning(
        "contact-us-email-input",
        contactData.enterValidEmail?.[currentLanguage],
        2
      )
      return false
    }

    let newObject = {
      first_name: firstName,
      last_name: lastName,
      email: email.toLowerCase(),
      subject: subject,
      content: messageContent,
      language: currentLanguage,
    }

    axios.post("/muni-management/contact-message/", newObject).then(result => {
      bringContactSuccess()
    })
  }

  const sendGetDemoRequest = e => {
    e.preventDefault()
    // Testing the user's email
    let validGetDemoEmail = emailRegex.test(email.toLowerCase())

    if (!validGetDemoEmail) {
      invalidElementWarning(
        "getDemo-email-input",
        contactData.enterValidEmail?.[currentLanguage],
        2
      )
      return false
    }

    let newObject = {
      first_name: firstName,
      last_name: lastName,
      email: email.toLowerCase(),
      company_name: companyName,
      company_size: selectedCompanySize.key,
      language: currentLanguage,
      company_role: companyRole,
      country: country.country,
      phone: phoneNumber,
      role: companyRole,
    }

    axios.post("/muni-management/demo-request/", newObject).then(result => {
      bringContactSuccess()
    })
  }

  /* USE EFFECTS */
  useEffect(() => {
    // Getting the location that the user called
    // let path = location.pathname
    if (path.includes("waitlist")) {
      setPageContentType("waitingList")

      // Clearing form data on page change
      clearFormData()
    } else if (path.includes("get-demo")) {
      setPageContentType("getDemo")
      clearFormData()
    } else {
      setPageContentType("contactUs")
      clearFormData()
    }
  }, [path])

  // Getting the email info from other pages
  // if entered into a communication bar
  useEffect(() => {
    if (location.state?.requestEmail) {
      // Setting the email
      setEmail(location.state.requestEmail)
    }
    if (location.state?.subjectType) {
      setSubject(location.state.subjectType)
    }
  }, [location])

  // Clearing form data on first load
  // just in case
  useEffect(() => {
    if (path.includes("get-demo")) {
      axios
        .get(
          `/muni-management/country/?lang=${localStorage.getItem("language")}`
        )
        .then(result => {
          setAllCountries([...result.data])
        })
    }
    // Scrolling to top for pages where
    // user comes from a lower point in the window
    window.scrollTo({
      left: 0,
      top: 0,
      behavior: "smooth",
    })
  }, [])

  /* */
  return (
    <Layout>
      <div className="contact">
        <div className="contact_illustration_container">
          <img
            src={
              pageContentType === "waitingList"
                ? waitingListIllustration
                : pageContentType === "contactUs"
                ? contactUsIllustration
                : welcomeIllustration
            }
            alt="illustration"
          />
        </div>
        {!contactedSuccesfully ? (
          <div className="contact_form_container">
            {pageContentType === "waitingList" ? (
              <Form
                //Common variables
                firstName={firstName}
                lastName={lastName}
                email={email}
                setFirstName={setFirstName}
                setLastName={setLastName}
                setEmail={setEmail}
                //Waiting List variables
                companySizeArrayList={companySizeArrayList}
                companyName={companyName}
                selectedCompanySize={selectedCompanySize}
                setCompanyName={setCompanyName}
                setSelectedCompanySize={setSelectedCompanySize}
                sendWaitingListRequest={sendWaitingListRequest}
                //Others
                pageContentType={pageContentType}
              />
            ) : pageContentType === "contactUs" ? (
              <Form
                //Common variables
                firstName={firstName}
                lastName={lastName}
                email={email}
                setFirstName={setFirstName}
                setLastName={setLastName}
                setEmail={setEmail}
                //Contact us variables
                subject={subject}
                messageContent={messageContent}
                setSubject={setSubject}
                setMessageContent={setMessageContent}
                sendContactUsRequest={sendContactUsRequest}
                //Others
                pageContentType={pageContentType}
              />
            ) : (
              <Form
                firstName={firstName}
                lastName={lastName}
                email={email}
                companyName={companyName}
                phoneNumber={phoneNumber}
                companyRole={companyRole}
                country={country}
                companySizeArrayList={companySizeArrayList}
                selectedCompanySize={selectedCompanySize}
                setFirstName={setFirstName}
                setLastName={setLastName}
                setEmail={setEmail}
                setCompanyName={setCompanyName}
                setSelectedCompanySize={setSelectedCompanySize}
                setCountry={setCountry}
                setCompanyRole={setCompanyRole}
                setPhoneNumber={setPhoneNumber}
                sendGetDemoRequest={sendGetDemoRequest}
                pageContentType={pageContentType}
                allCountries={allCountries}
              />
            )}
          </div>
        ) : (
          <div className="contact_success">
            <img
              src={circleCheckIcon}
              alt="Success Icon"
              className="contact_success_image"
            />
            <div className="contact_success_content">
              <span className="contact_success_content_header">
                {contactData.allSet?.[currentLanguage]}!
              </span>
              <div className="contact_success_content_body">
                <span>
                  {pageContentType === "waitingList"
                    ? contactData.thankYouForJoining?.[currentLanguage]
                    : pageContentType === "contactUs"
                    ? contactData.thankYouForReaching?.[currentLanguage]
                    : contactData.thankYouForRequestingDemo?.[currentLanguage]}
                  .&ensp;<i>&#127881;</i>
                </span>
                <span>
                  {pageContentType === "waitingList"
                    ? contactData.firstToKnow?.[currentLanguage]
                    : pageContentType === "contactUs"
                    ? contactData.willAnswerASAP?.[currentLanguage]
                    : contactData.getInTouch?.[currentLanguage]}
                  .&ensp;<i>&#129310;</i>
                </span>
              </div>
              <div className="contact_success_content_footer">
                <span>{contactData.useEmailBelow?.[currentLanguage]}:</span>
                <span>{email}</span>
              </div>
            </div>
          </div>
        )}
      </div>
    </Layout>
  )
}

export default Contact

// export const query = graphql`
//   query {
//     allProduct {
//       name
//       productPath: gatsbyPath(filePath: "/products/{Product.name}")
//       discountPath: gatsbyPath(filePath: "/discounts/{Product.name}")
//     }
//   }
// `
